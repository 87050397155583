import React from 'react'
import styled from 'styled-components'
import { vars } from './theme'

// Logos
import imgGoldenVentures from '../images/partners/Golden-Ventures.png'
import imgINova from '../images/partners/iNovia.png'
import imgIVP from '../images/partners/IVP.png'
import imgKensington from '../images/partners/Kensington.png'
import imgOMERS from '../images/partners/OMERS.png'
import imgRealVentures from '../images/partners/Real-Ventures.png'
import imgRelentlessVP from '../images/partners/Relentless-Venture-Partners.png'
import imgScaleUp from '../images/partners/ScaleUP-Ventures.png'
import imgStandup from '../images/partners/Standup-Ventures.png'
import imgVanedgeCap from '../images/partners/Vanedge-Capital.png'
import imgVersionOne from '../images/partners/Version-One.png'
import imgVFF from '../images/partners/VFF.png'

const vcList = [
  {
    src: imgGoldenVentures,
    href: 'https://golden.ventures',
    alt: 'Golden Ventures',
  },
  { src: imgINova, href: 'https://inovia.vc', alt: 'iNovia' },
  { src: imgIVP, href: 'https://informationvp.com', alt: 'IVP' },
  { src: imgKensington, href: 'http://kcpl.ca', alt: 'Kensington' },
  { src: imgOMERS, href: 'https://omersventures.com', alt: 'OMERS' },
  {
    src: imgRealVentures,
    href: 'https://realventures.com',
    alt: 'Real Ventures',
  },
  {
    src: imgRelentlessVP,
    href: 'https://www.relentlesspursuitpartners.com/',
    alt: 'Relentless Venture Partners',
  },
  { src: imgScaleUp, href: 'http://suv.vc/', alt: 'ScaleUP Ventures' },
  { src: imgStandup, href: 'https://standupvc.com', alt: 'StandUp Ventures' },
  {
    src: imgVanedgeCap,
    href: 'https://www.vanedgecapital.com/',
    alt: 'Vanedge Capital',
  },
  { src: imgVersionOne, href: 'https://versionone.vc', alt: 'Version One' },
  { src: imgVFF, href: 'http://vff.vc/', alt: 'VFF' },
]

const Container = styled.div`
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(2, 1fr);
  grid-gap: 1.5rem;

  @media screen and (min-width: ${vars.screenSmall}) {
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
  }
  @media screen and (min-width: ${vars.screenMedium}) {
    grid-template: repeat(3, 1fr) / repeat(4, 1fr);
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`

const InvestorPartnerGrid = () => (
  <Container>
    {vcList.map(vc => (
      <a href={vc.href} rel="noopener noreferrer" target="_blank" key={vc.href}>
        <img src={vc.src} alt={vc.alt} />
      </a>
    ))}
  </Container>
)

export default InvestorPartnerGrid
