import React from 'react'
import styled from 'styled-components'
import MetaHeader from '../components/MetaHeader'

import Layout from '../layouts'
import PageWrapper from '../components/PageWrapper'
import InvestorPartnerGrid from '../components/InvestorPartnerGrid'
import { Card } from '../components/common/Card'

const Wrapper = styled.div`
  margin: 4rem 0;
`

const Partners = () => (
  <Layout>
    <MetaHeader
      title="Partners"
      siteUrl="https://femalefunders.com/partners"
      keywords={`goldenventures inovia inoviavc informationvp
        kensington kcpl omers ventures omersventures realventures
        relentlesspursuitpartners scaleupventurs suvvc standupventures stantupvc
        vanedge capital vanedgecapital versionone versiononevc vff vffvc`}
    />
    <Wrapper>
      <PageWrapper>
        <h1>Partners</h1>

        <Card large>
          <InvestorPartnerGrid />
        </Card>
      </PageWrapper>
    </Wrapper>
  </Layout>
)

export default Partners
